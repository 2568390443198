import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Transition } from "react-transition-group";
import { Link } from "gatsby";
import urls from "./urls";
import adminEmails from "../util/adminEmails";
import { UserContext } from "./Layout";

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles: any = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0, pointerEvents: "none" },
  exited: { opacity: 0, pointerEvents: "none" },
};

export default function HamburgerMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const user = useContext(UserContext);

  return (
    <>
      <Button
        role="button"
        id="hamburger"
        aria-label="menu hamburger button"
        onClick={(e: any) => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }}
      >
        <Top isOpen={isOpen} className="line" />
        <Middle isOpen={isOpen} className="line" />
        <Bottom isOpen={isOpen} className="line" />
      </Button>
      <Transition in={isOpen} timeout={duration}>
        {(state) => (
          <Modal
            onClick={(e: any) => {
              if (e.target.href) {
                setIsOpen(false);
              }
            }}
            style={{ ...defaultStyle, ...transitionStyles[state] }}
          >
            <h2>Menu</h2>
            <ul>
              {urls.map((url) => {
                if (url.isAdmin && !adminEmails.includes(user.email)) {
                  return null;
                }
                return (
                  <li key={url.href}>
                    <Link activeClassName="active" to={url.href}>
                      {url.text}
                    </Link>
                  </li>
                );
              })}
              {/* <li>
                <Link to="/account">Account</Link>
              </li> */}
            </ul>
          </Modal>
        )}
      </Transition>
    </>
  );
}

const Modal = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: var(--bg);

  z-index: 100000;

  h2 {
    text-align: center;
    font-size: 50px;
  }

  ul {
    padding: 0;
    margin: 100px 0 0 0;
    list-style: none;
    display: flex;
    flex-direction: column;

    li {
      margin-bottom: 25px;
      width: 100%;
      height: 100%;
      text-align: center;

      a {
        display: block;
        width: 100%;
        height: 100%;
        font-size: 35px;
        color: var(--color);
      }
    }
  }
`;

const Button = styled.button<any>`
  box-sizing: content-box;
  min-width: 20px;
  padding: 0 30px;
  width: min-content;
  background: transparent;
  border: none;
  z-index: 1000000;
  outline: none;
  user-select: none;

  &:focus {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }

  .line {
    background: var(--color);
    width: 100%;
    height: 3px;
    margin: 5px;
    border-radius: 3px;
    border: none;
  }
  cursor: pointer;
`;

const Top = styled.div<any>`
  transition: transform 0.35s ease;
  transform: ${(props) =>
    props.isOpen ? "translateY(8px) rotate(45deg)" : "traslateY(0) rotate(0)"};
`;

const Middle = styled.div<any>`
  transition: opacity 0.25s ease;
  opacity: ${(props) => (props.isOpen ? 0 : 1)};
`;

const Bottom = styled.div<any>`
  transition: transform 0.35s ease;
  transform: ${(props) =>
    props.isOpen
      ? "translateY(-8px) rotate(-45deg)"
      : "traslateY(0) rotate(0)"};
`;
